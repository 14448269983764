import { t } from '@lib/i18n';
import StaticIcon from '@ui/StaticIcon';
import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import styled from 'windstitch';

const Container = styled.div(clsx(
  'flex',
  'flex-col',
));

const Icons = styled.div(clsx(
  'flex',
  'space-x-[-11px]',
));

const FunctionalIcon = styled('div', {
  className: clsx(
    '[border:3px_solid_#FBFCFF]',
  ),
});

const icons = [
  'issues',
  'service',
  'check-lists',
  'inventory',
  'passes',
  'announcements',
  'bookings',
  'checkins',
  'reports',
];

const Dot = styled.div(clsx(
  'h-[2px] w-[2px]',
  'rounded-full',
  'bg-primary400',
));

const FeaturesBlock = styled.div(clsx(
  'mt-3',
  'flex',
  'flex-col',
  'space-y-1',
));

const FeaturesRow = styled.div(clsx(
  'flex',
  'space-x-1',
  'items-center',
));

const FeatureRows = ({ data }) => {
  return data.map((row) => (
    <FeaturesRow>
      {row.map((feature, i) => (
        <>
          <a href={feature.link} target="_blank">
            <Typography variant="caption1" color="secondary100">
              {feature.title}
            </Typography>
          </a>
          {i === row.length - 1 ? null : (
            <Dot />
          )}
        </>
      ))}
    </FeaturesRow>
  ));
};

const AppFunctional = ({ className }) => {
  const featuresDividedOnRows = t('app_functional');
  return (
    <Container className={className}>
      <Icons>
        {
          icons.map((x) => (
            <div className="">
              <StaticIcon folder="main-page" name={x} className="box-content rounded-[56px] [border:3px_solid_#FBFCFF]" />
            </div>
          ))
        }
      </Icons>
      <MediaQuery at="lg">
        <FeaturesBlock>
          <FeatureRows data={featuresDividedOnRows.lg} />
        </FeaturesBlock>
      </MediaQuery>
      <MediaQuery at="xl">
        <FeaturesBlock>
          <FeatureRows data={featuresDividedOnRows.xl} />
        </FeaturesBlock>
      </MediaQuery>
    </Container>
  );
};

export default AppFunctional;
