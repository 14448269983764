import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import { createSlot } from '@lib/nano-slots';
import MediaQuery from '@ui/MediaQuery';
import Typography, { getTypographyClass } from '@ui/Typography';
import WizardProgress from '@ui/WizardProgress';
import { Layout } from '@widgets/layout';
import clsx from 'clsx';
import { SlotsProvider } from 'nano-slots';
import styled from 'windstitch';
import RegisterPageHeader from '../header/RegisterPageHeader';
import Banner from './banner';
import './registration-layout.scss';

const FooterSlot = createSlot('register-footer');
const WizardSlot = createSlot('register-wizard');
const FormSlot = createSlot('register-form');

const { block, element } = bem('registration-layout');

const Block = styled.div(clsx(
  'sm:mt-3 md:mt-4 lg:mt-4',
  element('main-section').className,
));

const FormContainer = styled.div(clsx(
  'flex',
  'flex-col',
  'lg:max-w-[440px]',
  'mx-auto',
));

const RegistrationLayout = ({ children, seo, withScrollEffect }) => {
  return (
    <SlotsProvider>
      <Layout
        seo={seo}
        showFooter={false}
        withTopOffset
        HeaderComponent={RegisterPageHeader}
        withScrollEffect={withScrollEffect}
        {...block()}
      >
        <MediaQuery greaterThan="md">
          <Banner />
        </MediaQuery>
        <Block>
          <div className="lg-down:grid-layout lg-down:mx-auto">
            <FormContainer>
              <FormSlot.Root />
              <FooterSlot.Root />
              <div className="pb-[38px] sm:hidden md:mt-6 lg:mt-8">
                <WizardSlot.Root />
              </div>
            </FormContainer>
            {children}
          </div>
        </Block>
      </Layout>
    </SlotsProvider>
  );
};

const FooterContainer = styled.div(clsx(
  'mt-3 sm:mb-3',
  getTypographyClass({
    variant: 'caption1',
    color: 'secondary100',
  }),
));

const LayoutFooter = ({ children }) => {
  return (
    <FooterSlot.Plug>
      <FooterContainer>
        {children}
      </FooterContainer>
    </FooterSlot.Plug>
  );
};

const LayoutForm = ({ cta, children }) => {
  return (
    <FormSlot.Plug>
      <Typography
        variant="header2"
        className="lg:mt-[62px]"
      >
        {t('registration.title')}
      </Typography>
      <Typography color="secondary200" className="mt-2">
        {cta}
      </Typography>
      <div className="lg:mt-5 lg-down:mt-4">
        {children}
      </div>
    </FormSlot.Plug>
  );
};

const LayoutWizard = (props) => {
  return (
    <WizardSlot.Plug>
      <WizardProgress {...props} />
    </WizardSlot.Plug>
  );
};

RegistrationLayout.Footer = LayoutFooter;
RegistrationLayout.Wizard = LayoutWizard;
RegistrationLayout.Form = LayoutForm;

export default RegistrationLayout;
